import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  NavigationExtras,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../login/services/login.service';
import { AppInitService } from '../services/app-init.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appInitService: AppInitService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let prompt = true;
    if (
      route.queryParams.prompt !== undefined &&
      route.queryParams.prompt === 'false'
    ) {
      prompt = false;
    }

    if (
      route &&
      route.queryParams &&
      route.queryParams.code &&
      route.queryParams.session_state
    ) {
      const code = route.queryParams.code;
      const state = route.queryParams.session_state;

      return this.loginService.loginWithCode(code, state).pipe(
        catchError((_) => this.redirectToOauthServer()),
        tap((_) =>
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              code: null,
              scope: null,
              session_state: null
            },
            queryParamsHandling: 'merge' // remove to replace all query params by provided
          })
        ),
        mergeMap((_) => this.checkUserInfo(route))
      );
    } else {
      return this.checkUserInfo(route, prompt);
    }
  }

  private checkUserInfo(
    route: ActivatedRouteSnapshot,
    prompt: boolean = true
  ): Observable<boolean> {
    const currentNavigation = this.router.getCurrentNavigation();
    let currentRoute = this.router.url;
    if (
      currentNavigation &&
      currentNavigation.extras &&
      currentNavigation.extras.state &&
      currentNavigation.extras.state.currentRoute
    ) {
      currentRoute = currentNavigation.extras.state.currentRoute;
    }
    const navigationExtras: NavigationExtras = {
      state: {
        currentRoute
      }
    };

    // used to custom redirection
    const routerNavigateData = route.data;
    const routeRequested = document.location.pathname;
    const isRouterNavigateData: boolean =
      routerNavigateData.routerNavigateIfConnected &&
      routerNavigateData.routerNavigateIfNotConnected;

    return this.loginService.getUserInfo().pipe(
      catchError(() => {
        // is not connected
        return this.onNotConnected(
          isRouterNavigateData,
          routerNavigateData,
          navigationExtras,
          routeRequested,
          prompt
        );
      }),
      mergeMap((userInfo) => {
        if (!userInfo) {
          // is not connected
          return this.onNotConnected(
            isRouterNavigateData,
            routerNavigateData,
            navigationExtras,
            routeRequested,
            prompt
          );
        } else {
          // is connected
          return this.onConnected(
            isRouterNavigateData,
            routerNavigateData,
            navigationExtras
          );
        }
      })
    );
  }

  private onConnected(
    isRouterNavigateData: boolean,
    routerNavigateData,
    navigationExtras: NavigationExtras
  ) {
    if (isRouterNavigateData) {
      return this.router.navigate(
        ['/' + routerNavigateData.routerNavigateIfConnected],
        navigationExtras
      );
    }
    return of(true);
  }

  private onNotConnected(
    isRouterNavigateData: boolean,
    routerNavigateData,
    navigationExtras: NavigationExtras,
    routeRequested: string,
    prompt: boolean
  ) {
    if (isRouterNavigateData) {
      this.router.navigate(
        ['/' + routerNavigateData.routerNavigateIfNotConnected],
        navigationExtras
      );
    } else {
      this.redirectToOauthServer(prompt);
    }
    return of(false);
  }

  private redirectToOauthServer(prompt: boolean = true): Observable<any> {
    const redirectUri = `${window.location.protocol}//${window.location.host}${environment.OAUTH_REDIRECT_URI}`;
    const codeVerifierChallenge = this.loginService.generateCodeVerifier();

    const params = [];

    if (prompt) {
      params.push(`prompt=${environment.OAUTH_PROMPT}`);
    }
    params.push(`client_id=${environment.OAUTH_CLIENT_ID}`);
    params.push(`redirect_uri=${redirectUri}`);
    params.push(`response_type=${environment.OAUTH_RESPONSE_TYPE}`);
    params.push(`code_challenge=${codeVerifierChallenge}`);
    params.push(`code_challenge_method=S256`);

    params.push(`scope=${environment.OAUTH_SCOPE.join('%20')}`);

    // eslint-disable-next-line max-len
    window.location.href = `${this.appInitService.getConfig('OAUTH_BASE_URL')}${
      environment.OAUTH_AUTHORIZE_ENDPOINT
    }?${params.join('&')}`;
    return of(null);
  }
}
