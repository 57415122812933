<div [formGroup]="form">
  <h1 mat-dialog-title>{{data.title | translate}}</h1>
  <div mat-dialog-content class="field">
    <mat-form-field>
      <input type="{{data.type}}" formControlName="editField" [attr.minLength]="data.format?.minLength"
        [attr.maxlength]="data.format?.maxLength" [placeholder]="data.key | translate" matInput>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button (click)="onNoClick()">{{'global.action.cancel' | translate}}</button>
    <button mat-flat-button color="accent" [disabled]="form.invalid" (click)="onYesClick()"
      cdkFocusInitial>{{'global.action.save' | translate}}</button>
  </div>
</div>