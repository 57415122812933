import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone_fr'
})
export class PhoneFrPipe implements PipeTransform {
  transform(value: any, phone: boolean, args?: any): any {
    return phone && value && value.length === 12
      ? `0${value.substring(3, 4)} ${value.substring(4, 6)} ${value.substring(
          6,
          8
        )}
       ${value.substring(8, 10)} ${value.substring(10, 12)}`
      : value;
  }
}
