import { UserInfo } from './../../models/user-info.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss']
})
export class LoginBannerComponent {
  @Input() userInfo: UserInfo;
  @Output() logout = new EventEmitter<void>();
  constructor() {}

  public disconnect() {
    this.logout.emit();
  }
}
