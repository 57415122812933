import { frFrBillsTranslation } from '../bills/lang/fr-FR';
import { frFrNotificationsTranslation } from '../notifications/lang/fr-FR';
import { frFrOffersTranslation } from '../offers/lang/fr-FR';
import { frFrPersonsTranslation } from '../persons/lang/fr-FR';
import { frFrPortalTranslation } from '../portals/lang/fr-FR';
import { frFrToolTranslation } from '../tools/lang/fr-FR';
import { frFrHomeTranslation } from './../home/lang/fr-FR';

export const frFrGlobalTranslation = {
  global: {
    lang: 'Français',
    validation: {
      required: 'Ce champ est requis.',
      invalidField: 'Veuillez saisir un {{fieldName}} valide.'
    },
    action: {
      cancel: 'Annuler',
      save: 'Enregistrer',
      delete: 'Supprimer',
      deleteOffer: "Supprimer l'offre",
      update: 'Corriger',
      add: 'Ajouter',
      activate: 'Activer',
      next: 'Suivant',
      completeSubscription: 'Finaliser mon abonnement',
      close: 'Fermer'
    }
  }
};

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

const importTranslation = {};
// add dependencies here: mergeDeep(importTranslation, yourLang1, yourLang2);
mergeDeep(
  importTranslation,
  frFrGlobalTranslation,
  frFrHomeTranslation,
  frFrPersonsTranslation,
  frFrPortalTranslation,
  frFrOffersTranslation,
  frFrToolTranslation,
  frFrBillsTranslation,
  frFrNotificationsTranslation
);

export const translation = importTranslation;
