import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

export interface DialogData {
  title?: string;
  value?: string;
  key?: string;
  type: DialogInputType;
  id?: string;
  format?: {
    minLength?: number;
    maxLength?: number;
  };
  validators?: ValidatorFn[];
}

export enum DialogAction {
  SAVE = 'SAVE'
}

export enum DialogInputType {
  text = 'text',
  password = 'password',
  tel = 'tel'
}

@Component({
  selector: 'app-dialog-edit',
  templateUrl: './dialog-edit.component.html',
  styleUrls: ['./dialog-edit.component.scss']
})
export class DialogEditComponent {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      editField: new UntypedFormControl(
        this.data.value ? this.data.value : '',
        {
          validators: this.data.validators
        }
      )
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.dialogRef.close({
      action: DialogAction.SAVE,
      id: this.data.id,
      value: this.form.controls['editField'].value
    });
  }
}
