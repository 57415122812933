export const frFrToolTranslation = {
  tool: {
    viewTitle: 'Outils admin',
    type: 'Type',
    name: 'Outil',
    description: 'Description',
    launch: 'Lancer',
    icn: {
      name: 'Outils iCownect',
      description: 'Api iCownect, batch de désabonnement automatique...',
      notify: {
        name: 'Notifier iCownect',
        description:
          "Notifie iCownect par un update ou un close en fonction du statut actuel de l'abonnement",
        force: 'Forcer la fermeture du compte chez iCownect',
        edeNumber: "Numéro d'exploitation",
        result: 'Retour du webservice',
        submit: {
          success: 'iCownect a été notifié.'
        }
      },
      autounsubscribe: {
        name: 'Désabonnement automatique iCownect',
        description:
          'A lancer avant facturation Innoval pour notifier iCownect des abonnements à mettre à jour.',
        date: 'Force la date de lancement du désabonnement automatique (défaut date du jour) Format : yyyy-MM-ddTHH:mm:ss.fffZ',
        result: 'Retour du webservice',
        submit: {
          success: "Les mises à jour d'abonnement iCownect ont été réalisées."
        }
      }
    },
    innov: {
      name: 'Outils Import utilisateur Innov',
      description: 'Ajout de nouveau utlisateur dans la base de données'
    },
    buttons: {
      upload: 'Envoyer'
    }
  }
};
