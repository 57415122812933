import { translations } from './lang/lang';
import { BehaviorSubject } from 'rxjs';
import {
  rightTriggerAnimation,
  leftTriggerAnimation
} from './core/animations/fade.animation';
import { Component, OnInit } from '@angular/core';
import { DualScreen } from './core/models/dual-screen.abstract';
import { Router, RouterOutlet, NavigationStart } from '@angular/router';
import { UserInfo } from './login/models/user-info.interface';
import { LoginService } from './login/services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [leftTriggerAnimation, rightTriggerAnimation]
})
export class AppComponent extends DualScreen implements OnInit {
  showLeftOutlet: boolean;
  title = 'AGRI MAKER Portail Administration';

  constructor(
    protected readonly router: Router,
    private readonly loginService: LoginService,
    translateService: TranslateService
  ) {
    super();
    this.showLeftOutlet = false;

    this.sub$.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          // TODO : check if it's possible to do it better
          if (event.url.indexOf('login') !== -1) {
            this.showLeftOutlet = false;
          } else {
            this.showLeftOutlet = true;
          }
        }
      })
    );

    // load translate content
    for (const language in translations) {
      // eslint-disable-next-line no-prototype-builtins
      if (translations.hasOwnProperty(language)) {
        translateService.setTranslation(language, translations[language]);
      }
    }
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('fr-FR');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('fr-FR');
  }

  public ngOnInit() {
    this.sub$.push(this.loginService.getUserInfo().subscribe());
  }

  public getUserInfo(): BehaviorSubject<UserInfo> {
    return this.loginService.userInfo;
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public onRouteActivated(event: any) {
    this.showLeftOutlet = true;
  }

  public onRouteDeactivated(event: any) {
    this.showLeftOutlet = false;
  }

  public onLogout() {
    this.loginService.logout().subscribe(() => {
      this.navigateTo('home', null);
    });
  }
}
