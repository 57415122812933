import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(array: any[], field: string, order: boolean): any[] {
    if (field === '') {
      return array;
    }

    const result = order ? 1 : -1;

    if (field && array) {
      array.sort((a, b) => (a[field] > b[field] ? result : -result));
    }

    return array;
  }
}
