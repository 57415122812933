export const frFrPortalTranslation = {
  portal: {
    viewTitle: 'Gestion des portails',
    name: 'Portail',
    url: 'Url',
    contact: 'Contact',
    description: 'Description',
    mailAssistance: 'Mail assistance',
    phoneAssistance: 'Téléphone assistance',
    assistanceHours: 'Horaires assistance',
    contactEmail: 'Contact',
    modifier: 'Modifier',
    submit: {
      success: 'Les modifications ont été sauvegardées.'
    }
  }
};
