import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewComponent } from './components/view/view.component';
import { CardComponent } from './components/card/card.component';
import { TranslateModule } from '@ngx-translate/core';
import { SortByPipe } from './pipes/sort-by.pipe';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { LabelComponent } from './components/label/label.component';
import { DialogEditComponent } from './components/dialog-edit/dialog-edit.component';
// eslint-disable-next-line max-len
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NguiInViewComponent } from './components/ngui-in-view/ngui-in-view.component';
import { PhoneFrPipe } from './pipes/phone-fr.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

@NgModule({
  declarations: [
    ViewComponent,
    CardComponent,
    LabelComponent,
    SortByPipe,
    LabelComponent,
    DialogEditComponent,
    NguiInViewComponent,
    PhoneFrPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    MatListModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRippleModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatChipsModule
  ],
  exports: [
    CommonModule,
    DialogEditComponent,
    TranslateModule,
    FormsModule,
    ViewComponent,
    CardComponent,
    LabelComponent,
    SortByPipe,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    MatListModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRippleModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatChipsModule,
    NguiInViewComponent,
    PhoneFrPipe
  ]
})
export class SharedModule {}
