import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export enum DualScreenEvent {
  REFRESH_DATA = 'refreshData'
}

@Injectable()
export abstract class DualScreen implements OnDestroy {
  protected abstract router: Router;
  protected authenticationChange: EventEmitter<void>;
  protected sub$: Subscription[];

  constructor() {
    this.sub$ = [];
  }

  public navigateTo(
    primary: string,
    auxiliary?: string,
    extraParams?: NavigationExtras
  ) {
    const outlets: { primary: string; auxiliary?: string } = {
      primary,
      auxiliary: auxiliary ? auxiliary : null
    };
    this.router.navigate([{ outlets }], extraParams);
  }

  public refreshAuthentication(callback: () => void) {
    if (this.authenticationChange) {
      this.sub$.push(
        this.authenticationChange.subscribe(() => {
          console.log('callback', callback);
          callback();
        })
      );
    } else {
      console.error(
        '"authenticationChange" not present in extended class please initilize it'
      );
    }
  }

  public ngOnDestroy() {
    this.sub$.forEach((subscription) => subscription.unsubscribe());
  }
}
