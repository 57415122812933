import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'ads',
    loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ads',
    loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./offers/offers.module').then((m) => m.OffersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./offers/offers.module').then((m) => m.OffersModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'portals',
    loadChildren: () =>
      import('./portals/portals.module').then((m) => m.PortalsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'portals',
    loadChildren: () =>
      import('./portals/portals.module').then((m) => m.PortalsModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('./tools/tools.module').then((m) => m.ToolsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('./tools/tools.module').then((m) => m.ToolsModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'segmentation',
    loadChildren: () =>
      import('./segmentation/segmentation.module').then(
        (m) => m.SegmentationModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'segmentation',
    loadChildren: () =>
      import('./segmentation/segmentation.module').then(
        (m) => m.SegmentationModule
      ),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'bills',
    loadChildren: () =>
      import('./bills/bills.module').then((m) => m.BillsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bills',
    loadChildren: () =>
      import('./bills/bills.module').then((m) => m.BillsModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'bills_not_paid',
    loadChildren: () =>
      import('./bills-not-paid/bills-not-paid.module').then(
        (m) => m.BillsNotPaidModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'bills_not_paid',
    loadChildren: () =>
      import('./bills-not-paid/bills-not-paid.module').then(
        (m) => m.BillsNotPaidModule
      ),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'bills_not_paid/result',
    loadChildren: () =>
      import('./bills-not-paid/bills-not-paid.module').then(
        (m) => m.BillsNotPaidModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'bills_not_paid/result',
    loadChildren: () =>
      import('./bills-not-paid/bills-not-paid.module').then(
        (m) => m.BillsNotPaidModule
      ),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./persons/persons.module').then((m) => m.PersonsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./persons/persons.module').then((m) => m.PersonsModule),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard],
    outlet: 'auxiliary'
  },
  {
    path: '',
    redirectTo: '/users(auxiliary:home)',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
