import { Message } from './../../persons/models/message.model';

import { Injectable, NgZone } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { ToasterService } from '../services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public toasterService: ToasterService,
    private readonly zone: NgZone,
    public router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (
          err instanceof HttpErrorResponse &&
          !err.url.endsWith('/api/users/me')
        ) {
          if (err.status === 401) {
            this.navigateTo('home', null);
            return throwError(err);
          } else {
            this.toasterService.open(
              `Error ${err.status}`,
              `${err.error.Message}`
            );
          }
        }
        return throwError(err);
      })
    );
  }

  private navigateTo(
    primary: string,
    auxiliary?: string,
    extraParams?: NavigationExtras
  ) {
    const outlets: { primary: string; auxiliary?: string } = {
      primary,
      auxiliary: auxiliary ? auxiliary : null
    };
    this.router.navigate([{ outlets }], extraParams);
  }
}
