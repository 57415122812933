import { Identity } from './../models/identity.model';
export const frFrPersonsTranslation = {
  persons: {
    title: 'Abonnés',
    filters: {
      mail: 'Mail',
      name: 'Nom',
      subscriptionPeriod: 'Période inscription',
      identity: 'Identité',
      identityStatus: 'Statut identité',
      identityStatusList: {
        activated: 'Activé',
        closed: 'Clos'
      },
      corporateName: 'Raison sociale',
      clientNumber: 'Numéro client',
      siretNumber: 'Numéro de siret',
      propertyNotVerified: "Données d'exploitation à valider",
      offerPackageOption: 'Offre / Package/Option',
      offerStatus: 'Statut Offre',
      offerStatusList: {
        activated: 'Activé',
        closed: 'Clos'
      },
      subscriptionStatus: 'Statut abonnement',
      subscriptionStatusList: {
        revocation_required: 'En attente désabonn.',
        revoked: 'Désabonné',
        activation_required: 'En attente activation',
        activated: 'Activé'
      },
      portal: 'Portail',
      provider: 'Fournisseur',
      externalManagement: 'Gestion externe',
      bool: {
        true: 'Oui',
        false: 'Non'
      }
    },
    buttons: {
      deleteFilters: 'Effacer filtres',
      search: 'Chercher',
      export: 'Exporter résultat'
    },
    list: {
      name: 'Nom',
      email: 'Mail',
      createWith: 'Portail',
      subscriptionDate: 'Date inscription',
      identity: {
        name: 'Identité',
        type: 'Type Identité',
        status: 'Statut'
      }
    },
    infos: {
      title: 'Détails Abonné',
      title2: 'Info. utilisateur',
      fullname: 'Nom, Prénom',
      email: 'Email',
      adress: 'Adresse',
      fixphone: 'N° Tél. fixe',
      mobilephone: 'N° Tél. mobile',
      dateSubscription: 'Date de souscription',
      block: 'Bloquer',
      unBlock: 'Débloquer',
      close: 'Résilier',
      login_as: 'Se connecter'
    },
    identities: {
      title: 'Identités',
      name: 'Identité',
      type: 'Type Identité',
      identityDateStatus: 'Statut',
      identityStatusList: {
        activated: 'Activé',
        waiting: 'A traiter',
        closed: 'Clos'
      }
    },
    payments: {
      title: 'Moyens de paiements',
      type: 'Type',
      details: 'Détail',
      createdAt: 'Créé le',
      status: 'Statut',
      addManual: 'Ajouter mode manuel',
      fullname: 'Nom complet',
      address: 'Adresse',
      identity: 'Identité',
      statusList: {
        activated: 'Activé',
        revokated: 'Clos',
        waiting: 'En attente'
      },
      removePayment: 'Supprimer'
    },
    roles: {
      title: 'Rôles',
      scope: 'Domaine',
      role: 'Rôle',
      removeClaim: 'Supprimer',
      scopes: 'Domaines',
      roles: 'Rôles',
      addClaim: 'Ajouter',
      scopesLst: {
        farmer: 'Portail agriculteur',
        admin: 'Portail admin',
        company: 'Portail entreprise'
      },
      rolesLst: {
        user: 'Utilisateur',
        consult_identity: 'Accès identité',
        consult_bill: 'Accès factures',
        consult_subscription: 'Accès abonnement',
        consult_user: 'Accès utilisateurs',
        admin_identity: 'Admin identité',
        admin_bill: 'Admin facture',
        admin_subscription: 'Admin abonnement',
        admin_user: 'Admin utilisateur',
        admin_all: 'Super admin',
        admin_user_email: 'Admin email utilisateur',
        admin_ads: 'Admin Petites annonces'
      }
    },
    subscriptions: {
      title: 'Abonnements',
      name: 'Nom',
      identity: 'Identité rattachée',
      identifier: 'Identifiant',
      createdAt: 'Abonnement',
      createdAtDate: 'Date abonnement',
      activatedAt: 'Activation',
      activationDate: 'Date activation',
      revocationRequiredAt: 'Dem. désactivation',
      revokedAt: 'Désabonnement',
      revocationDate: 'Date désabonnement',
      status: 'Statut',
      statusLabels: {
        revocationRequired: 'En attente désabonn.',
        revoked: 'Désabonné',
        activationRequired: 'En attente activation',
        activated: 'Activé',
        externalManagementSince: `Gestion externe depuis le`,
        externalManagementDisableSince: `Gestion externe : désabonnement le`
      },
      activateButton: 'Activer',
      revocateButton: 'Désabonner'
    },
    lastBills: {
      title: 'Factures',
      othersBills: 'Voir toutes les factures',
      finalPaymentMethodName: {
        check: 'Chèque',
        transfer: 'Virement',
        debit: 'Prélèvement',
        creditNote: 'Facture avoir'
      }
    },
    nextBill: {
      title: 'Prochaine facture',
      amount: 'Montant total HT',
      vatamount: 'Montant total TTC',
      openNextBillDetailsList: 'Voir le détail'
    },
    identityDetail: {
      title: 'Identité',
      info: {
        title: 'Informations',
        name: 'Raison sociale',
        address: 'Adresse',
        identity: 'Identité',
        clientNumber: 'Numéro client',
        siret: 'Numéro de siret',
        numagrit: 'NumAgrit',
        nosiret: 'Pas de siret ou numagrit',
        data: 'Informations complémentaires',
        activate: "Activer l'identité",
        revokate: "Désactiver l'identité",
        activity: 'Activité',
        legalCategory: 'Catégorie légale',
        taxIdentificationNumber: 'Numéro de TVA',
        activityBeginAt: "Démarrage de l'activité",
        countOfRepresentatives: 'Nombre de représentants',
        employmentSizeRanges: "Tranche d'effectif",
        departmentNumber: 'Département',
        longitude: 'Longitude',
        latitude: 'Latitude'
      },
      properties: {
        title: 'Propriétés',
        ede_number: "Numéro d'exploitation",
        marking_code: 'Indicatif de marquage',
        verified: 'Valider',
        revoked: 'Supprimer'
      }
    },
    bills: {
      title: 'Factures Abonné',
      id: 'Numéro de Facture',
      date: 'Date',
      amount: 'Montant HT',
      vatamount: 'Montant TTC',
      paymentMethod: 'Mode paiement',
      status: 'Statut',
      statusLabels: {
        paid: 'Payé',
        notPaid: 'A payer'
      }
    },
    bill: {
      edit: {
        title: 'Gestion de la facture'
      }
    },
    pendingBill: {
      title: 'Détail Prochaine facture',
      amount: 'Montant HT',
      vatamount: 'Montant TTC'
    },
    assistance: {
      title: 'Historique contacts',
      date: 'Date',
      message: 'Message',
      buttonAdd: 'Ok'
    },
    notifications: {
      title: 'Notifications',
      date: 'Date',
      type: 'Type',
      parameters: 'Paramètres',
      blocking: 'Bloquante',
      delete: 'Supprimer'
    }
  }
};
