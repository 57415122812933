import {
  trigger,
  animate,
  transition,
  style,
  query
} from '@angular/animations';

const fadeAnimation = [
  query(':enter', [style({ position: 'absolute', width: '50%', opacity: 0 })], {
    optional: true
  }),
  query(
    ':leave',
    [
      style({ position: 'absolute', width: '50%', opacity: 1 }),
      animate('0.5s ease-out', style({ opacity: 0 }))
    ],
    { optional: true }
  ),
  query(
    ':enter',
    [
      style({ position: 'absolute', width: '50%', opacity: 0 }),
      animate('0.5s ease-out', style({ opacity: 1 }))
    ],
    { optional: true }
  )
];

const fadeAnimationTransition = [transition('* => *', fadeAnimation)];

export const leftTriggerAnimation = trigger(
  'leftTriggerAnimation',
  fadeAnimationTransition
);
export const rightTriggerAnimation = trigger(
  'rightTriggerAnimation',
  fadeAnimationTransition
);
