import { DualScreen } from './../../../core/models/dual-screen.abstract';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface NavigateBackParams {
  primary: string;
  auxiliary?: string;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent extends DualScreen {
  @Input()
  title: string;

  @Input()
  navigateBackParams?: NavigateBackParams;

  constructor(protected router: Router) {
    super();
  }
}
