<mat-toolbar color="primary" class="toolbar">
    <div class="navigation" (click)="navigateTo('users', 'home')">Page d'Accueil</div>
    <app-login-banner *ngIf="getUserInfo()| async as userInfo" [userInfo]="userInfo" (logout)="onLogout()">
    </app-login-banner>
</mat-toolbar>

<div class="dual-screen">
    <section class="left-screen" [hidden]="!showLeftOutlet" [@leftTriggerAnimation]="getRouterOutletState(oL)">
        <router-outlet name="auxiliary" #oL="outlet" (activate)="onRouteActivated($event)"
            (deactivate)="onRouteDeactivated($event)"></router-outlet>
    </section>
    <section class="right-screen" [ngClass]="{'extend':!showLeftOutlet}"
        [@rightTriggerAnimation]="getRouterOutletState(o)">
        <router-outlet #o="outlet"></router-outlet>
    </section>
</div>

<!-- <footer class="primary"></footer> -->