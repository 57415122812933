import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input()
  title;

  @Input()
  content;

  @Input()
  redLabel?: boolean;

  @Input()
  nowrap?: boolean;

  @Input()
  icon?: string;

  constructor() {}

  displayIcon() {
    return this.icon;
  }
}
