export const DEFAULT_ENVIRONMENT = {
  production: false,
  API_BASE_URL: '/api',
  // eslint-disable-next-line max-len
  STATS_URL: '',
  excludeCachedUrl: ['/api/users/me', '/api/stores', 'api/portals'],
  OAUTH_AUTHORIZE_ENDPOINT: '/connect/authorize',
  OAUTH_CLIENT_ID: 'agrimaker_admin',
  OAUTH_SCOPE: ['openid', 'profile', 'agrimaker'],
  OAUTH_REDIRECT_URI: '/users(auxiliary:home)',
  OAUTH_RESPONSE_TYPE: 'code',
  OAUTH_PROMPT: 'login',
  OAUTH_PROMPT_CREATE: 'create',
  OAUTH_FARMER_ADMIN_CLIENT_ID: 'agrimaker_admin_farmer',
  OAUTH_FARMER_ADMIN_SCOPE: ['openid', 'profile', 'agrimaker'],
  OAUTH_FARMER_ADMIN_CONSULT_SCOPE: 'consult_farmer',
  OAUTH_FARMER_ADMIN_ADMIN_SCOPE: 'admin_farmer',
  OAUTH_FARMER_ADMIN_REDIRECT_URI: '/home-support',
  defaultLocale: 'fr-FR'
};
