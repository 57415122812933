import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { SHA256, enc } from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { UserInfo } from './../models/user-info.interface';

@Injectable()
export class LoginService {
  private readonly apiUrl: string;

  userInfo: BehaviorSubject<UserInfo>;

  authenticationChange: EventEmitter<void>;

  constructor(private readonly httpClient: HttpClient) {
    this.apiUrl = environment.API_BASE_URL;
    this.userInfo = new BehaviorSubject(null);
    this.authenticationChange = new EventEmitter<void>();
  }

  loginWithCode(code: string, state: string): Observable<void> {
    const codeVerifier = this.getCodeVerifier();
    this.removeCodeVerifier();
    return this.httpClient
      .post<void>(`${this.apiUrl}/loginwithcode`, {
        code,
        state,
        codeVerifier
      })
      .pipe(
        tap((_) => localStorage.setItem('agata-stat', 'connected')),
        tap((_) => this.getUserInfo())
      );
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/logout`, {}).pipe(
      tap(() => {
        this.userInfo.next(null);
      })
    );
  }

  getUserInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(`${this.apiUrl}/users/me`).pipe(
      tap((infos) => {
        this.userInfo.next(infos);
        this.authenticationChange.emit();
      })
    );
  }

  isAuthorized(roles: string[]): boolean {
    if (this.userInfo.value && roles.length > 0) {
      return roles.some((role) => {
        return (
          this.userInfo.value.claims.findIndex(
            (claim) =>
              claim.role.toLowerCase() === role.toLowerCase() &&
              claim.scope.toLowerCase() === 'admin'
          ) !== -1
        );
      });
    }
    return false;
  }

  isAuthorizedForScope(roles: string[], scope: string): boolean {
    if (this.userInfo.value && roles.length > 0) {
      return roles.some((role) => {
        return (
          this.userInfo.value.claims.findIndex(
            (claim) =>
              claim.role.toLowerCase() === role.toLowerCase() &&
              claim.scope.toLowerCase() === scope
          ) !== -1
        );
      });
    }
    return false;
  }

  isAuthorizedForFarmer(roles: string[]): boolean {
    if (this.userInfo.value && roles.length > 0) {
      return roles.some((role) => {
        console.log(JSON.stringify(this.userInfo.value.claims));
        return (
          this.userInfo.value.claims.findIndex(
            (claim) =>
              claim.role.toLowerCase() === role.toLowerCase() &&
              claim.scope.toLowerCase() === 'farmer'
          ) !== -1
        );
      });
    }
    return false;
  }

  generateCodeVerifier(): string {
    const codeVerifier = this.strRandom(128);
    localStorage.setItem('codeVerifier', codeVerifier);

    const codeVerifierHash = SHA256(codeVerifier).toString(enc.Base64);
    return codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  private strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private getCodeVerifier() {
    return localStorage.getItem('codeVerifier');
  }

  private removeCodeVerifier() {
    localStorage.removeItem('codeVerifier');
  }
}
