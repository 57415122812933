export const frFrBillsTranslation = {
  bills: {
    title: 'Factures',
    filters: {
      billId: 'Numéro de facture',
      dueDate: "Date d'exigibilité",
      identity: 'Identité',
      corporateName: 'Raison sociale',
      clientNumber: 'Numéro client',
      activePaymentMethod: 'Moyen de paiement renseigné',
      paid: 'Payée',
      bool: {
        true: 'Oui',
        false: 'Non'
      },
      offerPackageOption: 'Offre / Package/Option',
      provider: 'Fournisseur',
      toRemind: 'Courrier à envoyer',
      lastRemind: 'Date du dern. courrier',
      nbRemind: 'Nb courriers',
      toPresent: 'A prélever',
      lastPresentation: 'Date du dern. prélevement',
      nbPresentation: 'Nb prélevements',
      withActiveSepa: 'Avec SEPA actif',
      activeSepaSince: 'SEPA actif depuis le',
      nbReject: 'Nb rejets prél.',
      stopRemind: 'Stop relance',
      withSepaSinceLastPresent: 'Avec nouveau SEPA depuis prélèvement',
      identityDueAmount: 'Montant dû supérieur à'
    },
    buttons: {
      deleteFilters: 'Effacer filtres',
      search: 'Chercher',
      export: 'Exporter résultat'
    },
    infos: {
      title: "Factures de l'utilisateur"
    }
  }
};
