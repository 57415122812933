export const frFrNotificationsTranslation = {
  searchForm: {
    targetting: {
      label: 'Sélection',
      optionOne: 'National',
      optionTwo: 'Départemental',
      colDepartmentCode: 'Code',
      colDepartmentName: 'Nom',

      errorEmpty: 'Erreur: le champ ne peut pas être vide'
    },
    creationRange: 'Période de création',
    publicationRange: 'Période de publication',
    status: 'Statut du message',
    statusList: {
      1: 'Refusé',
      2: 'En attente de validation',
      3: 'En attente de publication',
      4: 'En attente de mise en ligne',
      5: 'Modèle validé',
      6: 'Publié',
      7: 'Archivé',
      0: 'Tous'
    },
    type: 'Type de message',
    typeList: {
      model: 'Modèle de notification',
      default: 'Notification standard'
    }
  },
  notificationView: {
    noSelection: 'Pas de notification sélectionnée',
    createdBy: 'Créé par',
    offerTargetted: 'Offre ciblée',
    allOffers: 'Toutes les offres',
    departmentsTargetted: 'Département(s) ciblé',
    refuse: 'Refuser la publication',
    validate: 'Valider la publication',
    dialogs: {
      validation: {
        title: 'Valider la notification?',
        message: 'Êtes-vous sûr de vouloir valider la notification?',
        confirm: 'Valider',
        cancel: 'Annuler'
      },
      refusal: {
        title: 'Refuser la notification?',
        message: 'Êtes-vous sûr de vouloir refuser la notification?',
        motive: 'Indiquez ici la cause de refus de diffusion du message',
        confirm: 'Refuser',
        cancel: 'Annuler',
        invalidMotive: 'Le motif de refus ne peut pas être vide'
      }
    }
  },
  generic: {
    the: 'le'
  }
};
