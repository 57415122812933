export const frFrOffersTranslation = {
  offers: {
    title: 'Gestion des offres',
    filter: 'Filtre',
    name: 'Nom',
    country: 'Pays',
    supplier: 'Fournisseur',
    allEnable: 'Tout disponible',
    allDisable: 'Tout indisponible',
    portals: 'Portails',
    userType: `Type d'utilisateur`,
    departments: 'Départements',
    offerPackages: 'Niveaux',
    packageOptions: 'Options',
    packageDetails: 'Détails package',
    packageActivationDate: `Date d'activation`,
    packageCloseDate: 'Date de fermeture',
    optionDetails: 'Détails option',
    detail: 'Modifier',
    activate: 'Activer',
    disable: 'Désactiver',
    validate: 'Valider',
    closeDateRequired: 'Veuillez entrer une date de fermeture',
    pricingTitle: 'Tarif',
    price: 'Prix €HT',
    date: 'Date',
    description: 'Descriptif',
    label: 'Libellé',
    priceError: 'Veuillez entrer un tarif valide',
    dateError: 'Veuillez entrer une date valide',
    descriptionError: 'Veuilez entrer une description valide',
    labelError: 'Veuillez entrer un libellé valide',
    resetForm: 'Réinitialiser',
    detailTitle: `Détails de l'offre`,
    level: 'Niveau',
    packageName: 'Nom',
    option: 'Option',
    dateDebut: "Début d'application",
    dateFin: "Fin d'application"
  },
  packageOptionDetail: {
    packageEnabledOrDisabled: 'Package activé/désactivé',
    optionEnabledOrDisabled: 'Option activée/désactivée',
    errorOnCloseDate: 'Veuillez entrer une date de fermeture',
    errorOnOpenDate: "Veuillez entrer une date d'ouverture"
  }
};
