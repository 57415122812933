<div class="label-container">
  <div class="title">
    {{title | translate}}
  </div>
  <div [ngClass]="{'content': true, 'red': redLabel, 'noWrap': nowrap, 'icon': icon}">
    <span>
        {{content || '&nbsp;'}}
    </span> 
    <i *ngIf="displayIcon()" class="material-icons">
      {{icon}}
    </i>
  </div>
</div>